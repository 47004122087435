   /* ####################################################################################

                                    HOME

/* ####################################################################################*/
.wpb_revslider_element.wpb_content_element {
    margin: 0;
    margin-bottom: 50px;
}

.vc_btn3-container.custom-btn {
	a.vc_general.vc_btn3{
	    padding: 5px 30px;
    	font-size: 14px;
    	border-width: 1px;
	}
}

h2.vc_custom_heading.custom-title{
	position: relative;
	margin-bottom: 0;
	color: #999 !important;

	&:after {
	    content: '';
	    width: 75px;
	    height: 3px;
	    background: var(--color-blue);
	    position: absolute;
	    bottom: 0;
	    left: 0;
	}
}

h3.vc_custom_heading.custom-title2{
	text-transform: uppercase;

	@media (--portable){
		font-size: 30px !important;
	}
}


.home-about{
	margin-bottom: 50px;

	@media (--not_phone){
		&>.wpb_column.vc_column_container:last-child{
			padding-top: 100px;

			.wpb_column.vc_column_container{
				&:last-child{
					width: fit-content;
				}
				&:first-child{
					margin-left: 7%;
				}
			}
		}
	}
	.wpb_column.vc_column_container:last-child{
		.wpb_column:first-child {
			width: 50%;
			float: left;

			.vc_column-inner{

				@media (--not_phone){
					padding-top: 50px;
				}

			.wpb_single_image:first-child{
				margin-bottom: 15px;

				@media (--portable){
					text-align: center !important;
				}
			}
			.wpb_single_image:last-child{
				/*@media (--not_phone){*/
					max-width: 80%;
				    margin-right: 0;
				    margin-left: auto;
				/*}*/
/*				@media (--portable){
					margin-bottom: 15px;
					text-align: center !important;
				}*/
			}
		}
	}
		.wpb_column:last-child {
			width: 50%;
			float: left;

			.wpb_column{
				width: 50%;
				float: left;
			}
			
			.vc_column-inner{
			padding: 0;

			.wpb_single_image.wpb_content_element{
		/*		@media (--portable){
					text-align: center !important;
				}*/
			}

			img{
				height: 270px;
				width: auto;
			}
		}
		}
	}
	.wpb_text_column.wpb_content_element p{
		text-align: justify;
	}
}


.project-slide{
	/*padding-bottom: 40px;*/
	padding: 20px 0 60px;
	a.button.product_type_simple{
		display: none;
	}

	.product-excerpt{
		display: none;
	}

	.wcpscwc-product-slider {
		.slick-arrow{
			top: 100%;
			text-indent: inherit;
			background-image: none !important;
			width: 80px;
			height: initial;
			padding: 5px 20px !important;
			font-family: Open Sans;
			font-weight: 400;
			background: none !important;
			border: 1px solid var(--color-grey2) !important;
			color: var(--color-grey2) !important;
			
			&:focus,
			&:hover{
				background-image: none !important;
				background: var(--color-green) !important;
				border-color: var(--color-green) !important;
			}
		}
		
		.slick-prev{
			@media (--not_phone){
				left: inherit!important;
				right: 50.5% !important;
			}
		}

		.slick-next{
			@media (--not_phone){
				left: 50.5% !important;
			}
		}

		ul.products li.product a img{
		    max-height: 270px;
    		object-fit: cover;
    		transition: transform 1s ease-in-out;

    		&:hover{
    			transform: scale(1.2);
            	transition: transform 1s ease-in-out;
    		}
		}

		ul.products li.product h2{
			position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    color: white;
		    text-transform: uppercase;
		    font-weight: 400;
		    text-align: center;
		    line-height: 24px;
		    letter-spacing: 7px;
		    width: 100%;
		}
	}
	 .product-img-container{
	 	overflow: hidden;
	 }
	 .woocommerce ul.products li.product,.woocommerce-page ul.products li.product{
		/*width: 100vw!important;*/
	}
}

.home-solution{
	padding-bottom: 30px;

	.solution-title{
		margin-bottom: 30px;
		margin-top: 20px;
	}
	.solution-item{

		margin-bottom: 20px;

		@media (--not_phone){
			width: 50%;
			float: left;

			&:nth-last-child(2n+1){
				padding-left: 10px;
			}
			&:nth-last-child(2n){
				padding-right: 10px;
			}
		}



	}
}
.home-award{
	div.sa_hover_container {
	    text-align: center;
	    display: flex;
	    align-items: center;
	    justify-content: center;

	    img{
	    	max-height: 80px;
	    }
	}
	.white .sa_owl_theme .owl-nav button.owl-next{
    	background: none !important;

    	&:before{
    		content:"\f0da";
    		font-family: fontAwesome;
    		color: var(--color-grey2);
    		font-size: 25px;
    	}
    } 
    .white .sa_owl_theme .owl-nav button.owl-prev{
    	background: none !important;

    	&:before{
    		content:"\f0d9";
    		font-family: fontAwesome;
    		color: var(--color-grey2);
    		font-size: 25px;
    	}
    }
}

.contact-form{
	padding: 20px 0;
	form.wpcf7-form{

		.contact-split{
			@media (--desktop){
				display: flex;
				justify-content: space-between;

				p{
					width: 32%;
				}
			}

			&+p label{
				width: 100%;
			}
		}

		&>p:nth-last-child(2){
			text-align: center;
		}
	}
	label{
		font-weight: 600;
	}
	input.wpcf7-form-control.wpcf7-text::-webkit-input-placeholder, textarea.wpcf7-form-control.wpcf7-textarea::-webkit-input-placeholder{
		color: var(--color-grey-contact) !important;
	}
	input[type=submit] {
	    width: initial;
	    height: 40px;
	    font-size: 18px;
    	font-weight: 700;
    	background: var(--color-green);
    	color: white;
    	font-weight: 400;
    	border: none;
    	float: none;
    	border-radius: 0;

    	&:hover{
    		background: var(--color-grey2);
    		color: white;
    		transition: all 0.3s ease-in-out;
    	}

	}
	input.wpcf7-form-control.wpcf7-text{
		border: 1px solid var(--color-grey2);
	}
	textarea.wpcf7-form-control.wpcf7-textarea{
		border: 1px solid var(--color-grey2);
		height: 95px;
		width: 100%;
		height: 40px;
	}
	.vc_col-sm-6:last-child .vc_column-inner>.wpb_wrapper {
		@media (--desktop){
	    max-width: 585px;
	    padding-left: 50px;
	}
	}

}

.home-contact>.vc_col-sm-6:first-child .vc_column-inner {
    @media (--not_desktop){
	    height: 200px;
	}
}

@media (--not_desktop){
	.contact-form form.wpcf7-form{
		display: flex;
		flex-direction: column;

		p:nth-last-child(4){
			order: 2;
		}
		p:nth-last-child(2){
			order: 3;
		}
		div:nth-last-child{
			order: 4;
		}

	}
}

.home .contact-wrapper{
	background: white;
}

