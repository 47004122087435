   /* ####################################################################################

                                    OVERALL

/* ####################################################################################*/

#content .container.content-wrapper{
	padding-top: 0;
	padding-bottom: 0;
}
#mainnav ul li ul:after{
	left: 5px !important;
}

.mainnav>.menu-main-menu-container>ul{

	@media (--desktop){
		display: flex;
		align-items: center;
		justify-content: space-around;

		.menu-item ul.sub-menu{
			left: 0% !important;

			li.menu-item{
				border: none;
				/*background: white;*/

				a{
					border: none !important;
				}
			}
		}
	}

	
}

header#masthead{
	padding: 5px;
	position: fixed !important;
	background: none;

	.logo-container{
		width: fit-content;
    	position: relative;

    	&:before{
    		content: '';
		    background: rgba(255,255,255,0.8);
		    position: absolute;
		    height: 120%;
		    width: 100%;
		    top: -10px;
    	}
	}

	.site-logo{
		max-height: 85px;
		position: relative;
	}

	li.menu-item{
		a{
			font-size: 16px;
			font-family: 'Nunito';
			line-height: 20px;
		}
		@media (--desktop){
			padding: 0 3%;	
		}


		br{
			@media (--not_desktop){
				display: none;
			}				
		}
	}
	.sub-menu li.menu-item a{
		@media (--desktop){
			font-size: 14px !important;
		}
	}
	.current-menu-parent>a,
	li.current-menu-item>a{
		color: var(--color-green);
		font-weight: 600;


		@media (--desktop){
			&:after{
				content: '';
			    position: absolute;
			    bottom: 0;
			    height: 1px;
			    width: 50px;
			    background: #aada76;
			    bottom: -5px;
			    left: 50%;
			    transform: translate(-50%, -50%);
			}
		}
	}

	.current-menu-parent .sub-menu a:after{
		content: none;
	}
}

#masthead.float-header{
	background: linear-gradient(rgba(0,0,0,0.8),transparent);
}

.woocommerce #menu-item-146 > a{
	color: var(--color-green);
		font-weight: 600;


		@media (--desktop){
			&:after{
				content: '';
			    position: absolute;
			    bottom: 0;
			    height: 1px;
			    width: 50px;
			    background: #aada76;
			    bottom: -5px;
			    left: 50%;
			    transform: translate(-50%, -50%);
			}
		}
}

nav#mainnav{
	width: 100%;
}

body p{
	line-height: 26px;
}

body{
	h1,h2,h3,h4,h5,h6{
		font-family: Open Sans !important;
		/*font-weight: 700 !important;*/
	}
}


#sidebar-footer{
	padding: 50px 0 20px 0;

	@media (--not_desktop){
		padding: 30px 0 0px;
	}

	&>.container{

		@media (--not_phone){
			display: flex;
			justify-content: space-between;
		}
	}

.sidebar-column {

	@media (--not_desktop){
		text-align: center;
	}

	&:first-child{
		@media (--not_phone){
			/*width: 40%;*/
		}

		aside#media_image-2{
			margin-bottom: 10px;

			@media (--not_phone){
				text-align: right;
			}

			img{
				max-width: 250px !important;
			}
		}

		aside#text-2{
			padding-top: 20px;

			@media (--not_phone){
				text-align: right;
			}
		}
    }

    &:nth-child(2){
    	@media (--not_phone){
			width: fit-content;
		}

		.textwidget h3{
			font-size: 23px;
		    font-weight: 700;
		    margin: 0;
		}

		@media(--not_desktop){
			.contact-table{
				display: flex;
				justify-content: center;

				table{
					width: fit-content;
				}
			}
		}		
		td {
		    border: none;
		    text-align: left;
		    vertical-align: text-bottom;

		    &:first-child{
		    	width: 10px;
		    }
		}

		tr:first-child td{
			padding-bottom: 20px;
		}
    }
    &:last-child{
    	@media (--not_phone){
			width: 30%;
		}

		a{
			font-size: 25px;
			display: block;

			@media (--sidebar_footer){
				display: inline;
				margin: 0 10px;
			}
		}
   		
    }
    h3.widget-title{
    	padding-bottom: 5px;
    	color: var(--color-green);
    	font-weight: 400;
		font-style: italic;
		font-size: 12px;
    }

}
}

.content-area .hentry:first-child {
    padding: 0;
}


footer#colophon{
	

	.site-info.container{
		text-align: center;
		padding: 5px 0;

		&>* {
		    display: inline-block;
		    float: none;
		    font-size: 14px;

		    @media (--portable){
		    	line-height: 20px;
		    }
		}
	}
}

@media (--not_phone){
a.hoverInspiren{
	float: none;
}
}

footer#colophon .site-info.container{
	text-transform: uppercase;
	display: block; 
	padding-bottom: 10px;

	&>*{

		@media (--not_phone){
			line-height: 10px;
		}
		
	}

	.copyright{
		margin-bottom: 0px;
	}

	&>.inspiren{
		display: block;
		padding-top: 0;
	}

}

div#sidebar-footer,
.page-id-38 .contact-form{
	display: none;
}
.home #sidebar-footer{
	display: block;
}

.site .header-clone{
		display: none;
}

/*=====bottom contact button======*/
a.floating-button {
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    bottom: 80px;
    right: 30px;
    font-size: 20px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    z-index: 1000;
    animation: pulsess 3s infinite;
}
a.floating-button_email {
    bottom: 150px;
    background-color: #0e4980;
}
a.floating-button_phone {
    bottom: 220px;
    background-color: rgba(220, 16, 16, .45);
}
a.floating-button_whatsapp {
    font-size: 25px;
}
a.floating-button_fb{
	bottom: 290px;
	background-color: #3B5998;
}
@keyframes pulsess {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, .3);
  }
  30% {
    box-shadow: 0 0 0 5px rgba(0,0,0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
  80% {
    box-shadow: 0 0 0 5px rgba(0,0,0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}