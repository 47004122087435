.project-cover{
	background-image: url('https://project.inspiren.com.my/genisis/wp-content/uploads/2019/08/Projects-Banner.jpg');
	margin-bottom: 50px;
}

.archive,.single{
	#content>.content-wrapper>.row{
		display: flex;

		@media (--portable){
			flex-direction: column;
		}

		#primary{
			order: 2;

				width: 100%;

		}

		#secondary{
			@media (--not_phone){
				width: 20%;
			}
		}
	}
	h3.widget-title{
		display: none;
	}
}

.woocommerce{
	ul.product-categories{
		.cat-item{
			background: var(--color-grey-trans);
			padding: 3px 10px !important;
			margin-bottom: 5px;
			a{
				text-transform: uppercase;
				font-weight: 600;
			}

			&.current-cat{
				background: var(--color-grey);
				a{
					color: var(--color-green) !important;
				}
			}
		}
	}
}

.archive.woocommerce{
	ul.products li.product {
		h2{
			font-size: 18px;
			text-align: left;
		}
		.product-img-container{
			img{
				max-height: 200px;
    			object-fit: cover;
			}
		}
	}
	.product-excerpt{
		text-align: left !important;
		font-size: 14px;
		line-height: 20px;
		height: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	a.button.product_type_simple{
		float: left;
		font-weight: 400;
		border-radius: 0;
		background: transparent;
		border: 1px solid var(--color-grey2);
		color: var(--color-grey2);
		text-transform: capitalize;
		font-size: 14px;
		padding: 5px 15px;

		&:hover{
			background: var(--color-grey2);
			color: white;
			transition: 0.3s all ease-in-out;
		}

	}
}

div#secondary,
h3.archive-title,
.woocommerce-notices-wrapper,
p.woocommerce-result-count,
form.woocommerce-ordering,
nav.woocommerce-breadcrumb{
	display: none;
}
