.solution-row {
	.wpb_single_image.wpb_content_element{
		margin-bottom: 10px;
	}

	.wpb_column:last-child{
		@media (--not_phone){
			padding-left: 20px;
			padding-top: 40px;
		}
	}
}
.spray-img {
	padding-top: 50px;

	.wpb_column.vc_column_container:last-child{
		padding: 0;
	}
	.wpb_column.vc_column_container:first-child{
		.wpb_single_image:last-child img{
			width: 80%;
    		float: right;
		}
	}
}

.solution{
	margin-top: 50px;

	h4.vc_custom_heading.custom-title2{
		margin-top: 0;
    	margin-bottom: 50px;
	}

	.wpb_text_column.wpb_content_element{
		overflow-y: scroll; 
	}

	table{
		width: 100% !important;

		tbody{
			tr{
				td{
					font-family: 'Open Sans';
					font-weight: 700;
				}
			}
		}
	}

	.solution-gallery{
		li.isotope-item{
			width: 33%;
			padding: 10px;
		}
	}
}

.solution-row2{
	margin-top: 50px;

	ul{
		padding-left: 20px;
	}

	h4{
		margin-top: 5px;
	}
}

.v-cous{

	h4{
		margin-top: 5px;
	}
	td{
		font-weight: 400;

		strong{
			font-size: 18px;
			font-weight: 700;
		}
	}

	.wpb_text_column.wpb_content_element{
		overflow-y: scroll;
	}
}

.v-cous2{
	table{
		tr{
			td{
				text-align: left;
			    border: none;
			    line-height: 20px;
			    vertical-align: text-bottom;
			    font-size: 14px;
			    font-weight: 600;
			}
		}
	}
}

.vc_row.testimonial-slider{
	padding: 30px 0;
	
	.wpb_column.vc_column_container.vc_col-sm-6{
		@media (--not_phone){
			padding-left: 8%;
		} 
	}

	.wpb_single_image{

		@media (--not_phone){
			position: absolute;
			top: 0;
			left: 15px;

			img{
				max-height: 70px;
				width: auto;
			}
		}
	}
	.comment-content{
		margin-left: 0;
		margin-bottom: 20px;
		line-height: 26px;
	}
	.comment-title {
		font-weight: 600;

		strong {
		    font-weight: 900;
		}
	}
	.comment-date p{
		color: black;
	}
}