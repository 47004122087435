.bg-cover{
	height: unset;
	.vc_column-inner{
		padding-top: 0 !important;

		&>.wpb_wrapper{
			width: fit-content;
			position: relative;

			&:before{
				content: "";
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    background: rgba(51,51,51,.5);
			    width: 130%;
			    height: 130%;
			    transform: translate(-50%,-50%);
			}
		}
	}
	.cover-title{
		font-family: 'Nunito' !important;
		margin: 0;
		position: relative;
	}
}

.vision-mission{

	h4.vc_custom_heading.custom-title2{
		@media(--portable){
			margin-top: 0;
			text-align: center !important;
		}
	}
	.wpb_single_image img{
		max-width: 65px;
	}

	ul{
		padding-left: 20px;

		li{
			@media (--portable){
				padding-bottom: 15px;
				line-height: 24px;
			}
		}

		ul{
			margin-top: 5px;

			@media (--portable){
				margin-top: 10px;
			}
		}
	}
	
}

.about-value-sec{
	padding-top: 0 !important;
	margin-bottom: 50px;

	@media (--portable){
		overflow: hidden;
	}
	.value-row{
		.value{
			border: 5px solid white;

			&:before{
				content: '';
			    position: absolute;
			    height: 100%;
			    width: 100%;
			    background: rgba(0,0,0,0.3);
			}

			.wpb_single_image.wpb_content_element{
				display: flex;
    			flex-direction: column;
    			position: absolute;
    			bottom: 0;
    			margin-bottom: 20px;
    			margin-left: 20px;
    			opacity: 1;
    			left: 0;
    			transition: all 0.3s ease-in-out;


    			h2{
    				order: 2;
    				font-size: 18px;
    				color: white;
    			}

    			img{
    				max-width: 80px;
    			}
			}
			.value-content {
				    color: white;
				    opacity: 0;
				    left: 50px;
				    padding: 10px;
				    transition: all 0.3s ease-in-out;
				    position: relative;

				    h5{
				    	line-height: 25px;
				    	font-weight: 700 !important;
				    }

				    p{
				    	line-height: 24px;
				    }
				}

			&:hover{
				.value-content{
					opacity: 1;
					left: 0;
					transition: all 0.3s ease-in-out;
				}

				.wpb_single_image.wpb_content_element{
					opacity: 0;
					left: 50px;
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}
}

.showcase{
	padding-bottom: 20px;

	/*.video-row{
		.wpb_video_widget.wpb_content_element{
			margin-bottom: 20px;
		}
		h6{
			margin-bottom: 10px;
		}
	}*/
	.video-title {
		&>.vc_column-inner{
				display: flex;
				align-items: center;
				justify-content: center;

				&:before{
					@media (--not_phone){
						content: '';
					    width: 50%;
					    height: 1px;
					    position: absolute;
					    background: black;
					    left: 20px;
					    display: block;
				}
			}
			&>.wpb_wrapper{
				width: fit-content;
				padding: 50px;
				background: white;
				position: relative;

				h6.vc_custom_heading{
					margin-bottom: 5px;
					margin-top: 0;
				}

				.wpb_text_column.wpb_content_element{
					margin-bottom: 0;
				}
			}
		}
	}
}

.page-id-38 #content .container.content-wrapper{
	padding-bottom: 10px;
}

.award-row{
	&>.wpb_column:first-child{
		/*width: fit-content;*/
		padding-top: 20px;

		@media (--not_phone){
			padding-left: 7%;
		}

		.vc_row{
			display: flex;

		}

		.wpb_column{
			width: fit-content;

			&>.vc_column-inner{
				padding: 5px;
			}

			&:last-child{
				/*padding-top: 40px;*/
			}
		}
	}

	.vc_column-inner{
		@media (--portable){
			padding-top: 0 !important;
		}
	} 

	.vc_grid-item.vc_col-sm-2{
		width: 20%;
	}
}

.award-row2{
	img.vc_gitem-zone-img{
		height: 300px;
	    width: auto;
	    margin: auto;
	}
	.vc_gitem-zone.vc_gitem-zone-a.vc_gitem-is-link{
		background-size: contain !important;
	    background-repeat: no-repeat !important;
	    background-position: center !important;
	}
	.vc_media_grid .vc_grid.vc_row .vc_grid-item.vc_visible-item{
		width: 20%;
		display: inline-block;
	}
	.vc_pageable-slide-wrapper{
		text-align: center;
	}
}
