.single.woocommerce {
	p.price,
	.product_meta,
	section.related.products,
	ul.tabs.wc-tabs,
	.post-navigation,
	div#tab-reviews,
	.entry-thumb{
		display: none !important;
	}

	.product.type-product{

		@media (--portable){
			display: flex;
			flex-direction: column;

			.summary.entry-summary{
				order: 2;
			}
		}

	}

	.woocommerce-tabs.wc-tabs-wrapper{

		@media (--not_phone){
			clear: none !important;
			width: 48%;
			float: right;
		}

		h2{
			display: none;
		}
		

		.product-gallery{

			.wpb_gallery.wpb_content_element{
				margin-bottom: 0;
			}
			.wpb_image_grid .wpb_image_grid_ul {
				display: flex;
				justify-content: space-between;
				.isotope-item{
					position: static !important;
					width: 17%;
				}
			}

		}
	}

	h1.product_title.entry-title {
		line-height: 25px;

		span {
		    font-weight: 900;
		}
	}

	ol.flex-control-nav.flex-control-thumbs {
		margin-top: 10px;
		li{
			width: 14.28%;
		}
	}

	div.product{
	    .woocommerce-product-details__short-description p{
	    	font-size: 14px;
	    	line-height: 20px;
	    }

	}
}

.single.woocommerce #primary{
	.woocommerce-product-gallery__image{
		img{
			max-height: 350px;
			object-fit: cover;
		}
	}
}

.pdf a.vc_general {
    background: none;
    padding-left: 0;
    font-size: 16px;
    padding: 0;
}

.home-post.post-list .vc_grid-item-mini:hover:before{
	content: none;
}

.pswp__caption__center{
	display: none;
}