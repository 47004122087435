#sidebar-footer.contact-detail{
	.textwidget{
		h3{
			display: none;
		}
	}
}

.contact-form.contact-contact{
	padding-bottom: 30px;

	@media (--not_phone){
		form.wpcf7-form{
			&>*{
				width: 31.3%;
				margin: 1%;
			}
			&>p:nth-last-child(4){
				width: 64.7%;
			}

			&>p:nth-last-child(3){
				width: 64.7%;
			}
			&>p:nth-last-child(2){
				display: flex;
				input.wpcf7-form-control.wpcf7-submit{
					width: 50%;
					float: left;
				}
				input[type="reset"]{
					width: 50%;
					float: right;
				}
			}
		}
	}
	@media (--phone){
		form.wpcf7-form{
		&>p:nth-last-child(2){
			display: flex;
			flex-direction: column;

			input[type="reset"]{
				margin-top: 15px;
				width: 100%;
			}
			span.ajax-loader{
				order: 2;
			}
		}
	}
	}
	input.wpcf7-form-control.wpcf7-text{
		background: rgba(255,255,255,0.8);

		&::-webkit-input-placeholder{
			color: var(--color-blue) !important;
		}
	}
	textarea.wpcf7-form-control.wpcf7-textarea{
		background: rgba(255,255,255,0.8);
		height: 40px;

		&::-webkit-input-placeholder{
			color: var(--color-blue) !important;
		}
	}
	input[type="submit"]{
		background: var(--color-yellow);
		color: #333333;
	}
	input[type="reset"]{
		font-size: 18px;
		background: #333333;
		color: var(--color-yellow);
		font-weight: 600;
		height: 40px;
	}
}

.contact-detail{

	@media(--not_phone){
		display: flex;
		justify-content: center;
		
		.wpb_column{
			max-width: 370px;
		}
	}

	.info-title{
		@media (--portable){
			display: none;
		}
	}
	.contact-icon {
		a{
			display: inline-block;
			font-size: 0;
			margin-right: 20px;

			i{
				font-size: 25px;
			}
		}
		br{
			display: none;
		}
	}
	h5{
		margin-bottom: 10px;
	}

	h6{
		margin-bottom: 5px;
	}
	.wpb_text_column{
		margin-bottom: 20px;
	}
}

.contact-wrapper{
	background: var(--color-grey-trans);
}

.contact-detail2{
	padding-bottom: 10px;
}